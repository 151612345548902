import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";

export const GET_HOMES_OVERVIEW = gql`
  ${FRAGMENT_MEDIA_ITEM}
  query GetHomesOverview {
    homes(last: 500) {
      nodes {
        databaseId
        id
        slug
        title
        uri
        productStatus {
          enableSnipeLink
          fieldGroupName
          sectionId
          statusCustom
          statusMessage
        }
        featuredImage {
          node {
            altText
            slug
            sourceUrl
            title
            uri
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
        qmiHomeDetails {
          bathrooms
          bedrooms
          finishedSqrFt
          latitude
          longitude
          price
          type
          elevations {
            ...MediaItemFragment
          }
          neighborhood {
            ... on Neighborhood {
              slug
              neighborhood {
                neighborhoodArea
                thumbnail {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes {
                      file
                      fileSize
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
