<template>
  <div
    :class="[
      'organism neighborhoods-by-collection-map',
      'container-' + organism.containerWidth,
    ]"
  >
    <OrganismsHeadingTransition class="" :organism="organism" />
    <div class="relative pointer-events-none">
      <div
        id="map-wrap"
        class="block absolute -mt-[25px] lg:mt-0 lg:absolute lg:-top-7 lg:left-0 h-[0px] lg:h-full w-full isolate -z-10"
      >
        <div id="map" class="w-full h-full pointer-events-auto"></div>
      </div>
      <div
        class="flex items-start min-h-[820px] flex-row-reverse w-full mx-auto pointer-events-none max-w-screen-xl"
      >
        <div
          class="w-full lg:w-2/5 lg:max-w-[488px] shadow lg:my-0 xl:my-12 lg:mr-0 xl:mr-8 bg-white pointer-events-auto pb-20 lg:pb-0"
        >
          <div class="px-8 py-10 text-center text-white tabs-container">
            <h2 class="font-figtree font-bold text-[32px] drop-shadow-text">
              Our Communities
            </h2>
          </div>
          <div
            class="px-8 sm:px-6 pt-4 tabs-component lg:max-h-[clamp(600px,_75vh,_1200px)] sm:overflow-y-scroll overflow-hidden"
          >
            <div v-for="group in neighborhoodGroups">
              <MoleculesOverviewItemGroup
                v-if="group?.items?.length"
                :group="group"
                tab-id="communities"
              />
            </div>

            <p
              v-if="overview.filterResultsEmpty"
              class="pt-8 pb-12 text-gray-500"
            >
              There were 0 results for this collection at this time.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.neighborhoods-by-collection-map {
  .tabs-container {
    background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
  }
  .overview-group-heading {
    h3 {
      @apply whitespace-normal sm:whitespace-nowrap text-center min-w-[75%] sm:min-w-fit;
    }
  }
}

.gm-style .gm-style-iw[role="dialog"] {
  @apply p-0 rounded-none;
}

.gm-style .gm-style-iw[role="dialog"] > div {
  @apply !overflow-hidden;
}

.gm-style .gm-style-iw[role="dialog"] > button > span {
  @apply !hidden;
}

.gm-style .gm-style-iw[role="dialog"] > button {
  @apply !bg-gray-900 rounded-full !h-8 !w-8 !top-0 !right-0;
}

.gm-style .gm-style-iw[role="dialog"] > button::before {
  @apply content-[''] bg-[url('/img/cancel-circle.svg')] bg-no-repeat bg-center bg-[length:24px_24px] w-full h-full block;
}

.gm-style .gm-style-iw-tc::after {
  @apply !bg-fr-secondary-dark;
}

.infowindow-content.infowindow-model-homes,
.infowindow-content.infowindow-neighborhoods {
  @apply w-[283px] text-gray-800;
}

.infowindow-content.infowindow-qmi-homes {
  @apply w-[325px] text-gray-800;
}

.infowindow-content img {
  @apply w-full h-[184px] object-cover mb-4;
}

.infowindow-content .title {
  @apply font-figtree text-2xl font-bold text-center mb-3 px-1;
}

.infowindow-content.infowindow-qmi-homes .title {
  @apply mb-0;
}

.infowindow-content .subtitle {
  @apply text-base text-gray-500 font-figtree text-center mb-4 px-1;
}

.infowindow-content .plan {
  @apply font-figtree text-sm font-medium text-bc-wc-green text-center uppercase px-1 mb-3;
}

.infowindow-content .details {
  @apply flex justify-evenly mb-3 px-1;
}

.infowindow-content .details div span {
  @apply block first:text-[22px] last:text-xs last:font-bold text-center font-figtree;
}

.infowindow-content .directions-link {
  @apply block text-bc-dark-blue font-figtree text-base underline text-center flex justify-center gap-1 items-center font-medium mb-4 px-1;
}

.infowindow-content .directions-link::before {
  @apply content-[''] bg-bc-dark-blue bg-[url('/img/arrow-up-right.svg')] bg-no-repeat bg-center bg-[length:8px_8px] w-4 h-4 block rounded;
  clip-path: polygon(
    45% 2%,
    50% 1%,
    55% 2%,
    98% 45%,
    99% 50%,
    98% 55%,
    55% 98%,
    50% 99%,
    45% 98%,
    2% 55%,
    1% 50%,
    2% 45%
  );
}

.infowindow-content .explore-button {
  @apply bg-fr-secondary-dark block text-center text-white font-figtree font-medium text-lg py-[11px] after:content-['\00bb'] after:ml-1 px-1;
}

.selected-item {
  @apply outline outline-4 outline-fr-brown-med;
}
</style>

<script setup>
import { storeToRefs } from "pinia";
const overview = useOverview();
const { collectionFilter, mapInfo } = storeToRefs(overview);

const props = defineProps({
  organism: Object,
});

const hideGroupHeadingIfEmpty = () => {
  const groups = document.querySelectorAll(
    ".neighborhoods-by-collection-map .overview-item-group"
  );

  groups.forEach((group) => {
    const items = group.querySelectorAll("article.item");
    let visibleItemCount = 0;

    items.forEach((item) => {
      const itemIsVisible = item.parentNode.style.display !== "none";

      if (itemIsVisible) {
        visibleItemCount++;
      }
    });

    group.style.display = visibleItemCount ? "block" : "none";
  });
};

collectionFilter.value = props.organism.collection;

const filterByCollection = () => {
  const items = document.querySelectorAll(
    ".neighborhoods-by-collection-map .overview-item-group article.item"
  );

  let itemCounter = 0;
  items.forEach((item) => {
    item.parentNode.style.display = "none";
    if (
      kebabCase(item?.dataset?.collections).includes(
        kebabCase(props?.organism?.collection)
      )
    ) {
      item.parentNode.style.display = "block";
      itemCounter++;
    }
  });
  overview.filterResultsEmpty = itemCounter ? false : true;

  hideGroupHeadingIfEmpty();
};

const areasUnique = computed(() => {
  const areas = overview?.neighborhoods?.nodes?.map(
    (node) => node?.neighborhood.neighborhoodArea
  );
  // Return unique multidimensional array
  return (
    areas
      ?.map(JSON.stringify)
      .filter((e, i, a) => i === a.indexOf(e))
      .map(JSON.parse) ?? []
  );
});

const neighborhoodGroups = computed(() => {
  return areasUnique?.value?.map((area) => {
    return Array.isArray(area)
      ? {
          areaName: area[0],
          areaTitle: area[1],
          items: overview?.neighborhoods?.nodes?.filter(function (node) {
            return this[0] === node.neighborhood.neighborhoodArea[0];
          }, area),
          plans: overview?.plans?.nodes ?? [],
        }
      : {};
  });
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
  loadOverviewMap();
  updateOverviewMap(mapInfo, filterByCollection);
});

watch(mapInfo, () => {
  updateOverviewMap(mapInfo, filterByCollection);
});
</script>
