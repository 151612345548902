<template>
  <div :class="['organism our-team', 'container-' + organism.containerWidth]">
    <div class="w-full px-8 pt-12 bg-fr-beige mb-14">
      <h2 class="mx-auto mb-6 text-4xl font-semibold max-w-7xl font-figtree text-fr-text-dark">
        Select a department
      </h2>
      <ul class="flex flex-col flex-wrap pb-6 mx-auto sm:flex-row max-w-7xl">
        <li v-for="department in teamDetails.departments">
          <a :href="'#department-' + department.department"
            class="block mr-8 underline text-fr-primary-dark text-[15px] font-figtree mb-6 font-bold">{{
            department.department }}</a>
        </li>
      </ul>
    </div>
    <div v-for="department in teamDetails.departments" class="mx-auto shadow-md bg-fr-beige max-w-7xl mb-14">
      <OrganismsHeadingTransition :organism="{ title1: department.department, arrow: true }" class="max-w-7xl"
        :id="'department-' + department.department" />
      <div
        class="mx-auto w-fit grid grid-cols-[272px] md:grid-cols-[repeat(2,_272px)] lg:grid-cols-[repeat(3,_272px)] xl:grid-cols-[repeat(4,_272px)] gap-x-4 gap-y-8 max-w-full pt-7 pb-20 px-8 md:px-0">
        <div v-for="teamMember in department.teamMembers"
          class="self-start max-w-full bg-white border shadow-md border-neutral-300 relative">
          <img :src="getImageSrc(teamMember.photo, 'thumb-product-listing')" :alt="teamMember.name + ' headshot'"
            class="h-[250px] object-cover w-full" loading="lazy" />
          <div class="p-6 min-h-[160px]">
            <div class="mb-3 font-bold font-figtree text-bc-dark-blue">
              {{ teamMember.name }}
            </div>
            <div class="text-base text-gray-500 font-figtree">
              {{ teamMember.position }}
            </div>
            <p class="teamMember-link absolute bottom-4 cursor-pointer underline text-gray-500"
              v-if="teamMember.description" @click="toggleDescription(department, teamMember)">
              Show details
            </p>
            <div
              class="teamMember-description text-base top-0 left-0 bg-fr-primary-dark bg-opacity-80 text-white p-4 pt-8 font-figtree absolute overflow-y-auto w-full h-full"
              v-show="showDescription.department === department && showDescription.teamMember === teamMember">
              {{ teamMember.description }}
              <button class="absolute top-0 right-0 p-2 text-xl"
                @click="toggleDescription(department, teamMember)">X</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.our-team {
}
</style>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_TEAM_DETAILS } from "~/graphql/GetTeamDetails";
const { result } = useQuery(GET_TEAM_DETAILS);

const teamDetails = computed(() => result?.value?.page?.teamDetails ?? []);
//console.log("teamDetails", teamDetails);

const props = defineProps({
  organism: Object,
});

const showDescription = ref({
  department: null,
  teamMember: null,
});

const toggleDescription = (department, teamMember) => {
  if (showDescription.value.department === department && showDescription.value.teamMember === teamMember) {
    showDescription.value.department = null;
    showDescription.value.teamMember = null;
  } else {
    showDescription.value.department = department;
    showDescription.value.teamMember = teamMember;
  }
};

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
