<template>
  <div class="organisms">
    <component
      v-for="(organism, index) in organisms"
      :key="'organism-' + (index + 1)"
      :id="organism.id ? organism.id : 'organism-' + (index + 1)"
      :class="[
        organism.cssClasses,
        organism.type,
        'organism-' + (index + 1),
        'mb-20',
      ]"
      :is="
        organism.type === 'accordion'
          ? OrganismsAccordion
          : organism.type === 'banner-bar'
          ? OrganismsBannerBar
          : organism.type === 'brand-window' &&
            organism.brandWindowLayout === 'box-overlay'
          ? OrganismsBWBoxOverlay
          : organism.type === 'brand-window' &&
            organism.brandWindowLayout === 'split-slant'
          ? OrganismsBWSlant
          : organism.type === 'brand-window' &&
            (organism.brandWindowLayout === 'text-overlay-left' ||
              organism.brandWindowLayout === 'text-overlay-center')
          ? OrganismsBWTextOverlay
          : organism.type === 'events'
          ? OrganismsEvents
          : organism.type === 'flexible-content-columns'
          ? OrganismsFlexibleContentColumns
          : organism.type === 'form-cta'
          ? OrganismsFormCTA
          : organism.type === 'gallery-grid'
          ? OrganismsGalleryGrid
          : organism.type === 'heading-transition'
          ? OrganismsHeadingTransition
          : organism.type === 'hubspot-form'
          ? OrganismsHubSpotForm
          : organism.type === 'image'
          ? OrganismsImage
          : organism.type === 'locations-list'
          ? OrganismsLocationsList
          : organism.type === 'map-arbitrary'
          ? OrganismsMapArbitrary
          : organism.type === 'map-with-info'
          ? OrganismsMapWithInfo
          : organism.type === 'my-favorites-grid'
          ? OrganismsMyFavoritesGrid
          : organism.type === 'neighborhoods-by-collection-map'
          ? OrganismsNeighborhoodsByCollectionMap
          : organism.type === 'neighborhoods-map'
          ? OrganismsNeighborhoodsMap
          : organism.type === 'organism-slider'
          ? OrganismsOrgSlider
          : organism.type === 'our-team'
          ? OrganismsOurTeam
          : organism.type === 'rounded-image-bio'
          ? OrganismsRoundedImageBio
          : organism.type === 'single-product-grid'
          ? OrganismsSingleProductGrid
          : organism.type === 'simple-centered'
          ? OrganismsSimpleCentered
          : organism.type === 'simple-iframe'
          ? OrganismsSimpleIframe
          : organism.type === 'site-map-cta'
          ? OrganismsSiteMapCTA
          : organism.type === 'split-content'
          ? OrganismsSplitContent
          : organism.type === 'tabbed-products'
          ? OrganismsTabbedProducts
          : organism.type === 'tabbed-content'
          ? OrganismsTabbedContentGeneric
          : organism.type === 'tabbed-videos'
          ? OrganismsTabbedVideos
          : organism.type === 'testimonial-cta'
          ? OrganismsTestimonialCTA
          : organism.type === 'text-cta'
          ? OrganismsTextCTA
          : organism.type === 'timeline'
          ? OrganismsTimeline
          : organism.type === 'virtual-tour'
          ? OrganismsVirtualTour
          : organism.type === 'wysiwyg-block'
          ? OrganismsWysiwygBlock
          : 'div'
      "
      :organism="organism"
    />
  </div>
</template>

<script setup>
const OrganismsAccordion = resolveComponent("OrganismsAccordion");
const OrganismsBannerBar = resolveComponent("OrganismsBannerBar");
const OrganismsBWBoxOverlay = resolveComponent("OrganismsBWBoxOverlay");
const OrganismsBWSlant = resolveComponent("OrganismsBWSlant");
const OrganismsBWTextOverlay = resolveComponent("OrganismsBWTextOverlay");
const OrganismsEvents = resolveComponent("OrganismsEvents");
const OrganismsFlexibleContentColumns = resolveComponent(
  "OrganismsFlexibleContentColumns"
);
const OrganismsFormCTA = resolveComponent("OrganismsFormCTA");
const OrganismsGalleryGrid = resolveComponent("OrganismsGalleryGrid");
const OrganismsHeadingTransition = resolveComponent(
  "OrganismsHeadingTransition"
);
const OrganismsHubSpotForm = resolveComponent("OrganismsHubSpotForm");
const OrganismsImage = resolveComponent("OrganismsImage");
const OrganismsLocationsList = resolveComponent("OrganismsLocationsList");
const OrganismsMapArbitrary = resolveComponent("OrganismsMapArbitrary");
const OrganismsMapWithInfo = resolveComponent("OrganismsMapWithInfo");
const OrganismsMyFavoritesGrid = resolveComponent("OrganismsMyFavoritesGrid");
const OrganismsNeighborhoodsByCollectionMap = resolveComponent(
  "OrganismsNeighborhoodsByCollectionMap"
);
const OrganismsNeighborhoodsMap = resolveComponent("OrganismsNeighborhoodsMap");
const OrganismsOrgSlider = resolveComponent("OrganismsOrgSlider");
const OrganismsOurTeam = resolveComponent("OrganismsOurTeam");
const OrganismsRoundedImageBio = resolveComponent("OrganismsRoundedImageBio");
const OrganismsSimpleCentered = resolveComponent("OrganismsSimpleCentered");
const OrganismsSimpleIframe= resolveComponent("OrganismsSimpleIframe");
const OrganismsSingleProductGrid = resolveComponent(
  "OrganismsSingleProductGrid"
);
const OrganismsSiteMapCTA = resolveComponent("OrganismsSiteMapCTA");
const OrganismsSplitContent = resolveComponent("OrganismsSplitContent");
const OrganismsTabbedProducts = resolveComponent("OrganismsTabbedProducts");
const OrganismsTabbedContentGeneric = resolveComponent(
  "OrganismsTabbedContentGeneric"
);
const OrganismsTabbedVideos = resolveComponent("OrganismsTabbedVideos");
const OrganismsTestimonialCTA = resolveComponent("OrganismsTestimonialCTA");
const OrganismsTextCTA = resolveComponent("OrganismsTextCTA");
const OrganismsTimeline = resolveComponent("OrganismsTimeline");
const OrganismsVirtualTour = resolveComponent("OrganismsVirtualTour");
const OrganismsWysiwygBlock = resolveComponent("OrganismsWysiwygBlock");

const props = defineProps({
  organisms: Array,
});
</script>
