<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'organism testimonial-cta text-white',
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div class="mx-auto max-w-screen-lg">
      <div
        class="relative flex items-center justify-center w-20 mx-auto rounded-full quote-icon -top-10 bg-fr-secondary-lt aspect-square"
      >
        <img src="/img/quote-icon.svg" alt="" class="" loading="lazy"/>
      </div>
      <div class="testimonial-flex px-16 pb-20 xl:px-0">
        <p
          class="text-[24px] md:text-[39px] mb-4 leading-tight theme-text-xl"
        >
          {{ organism.text1 }}
        </p>
        <img
          class="inline-block mr-5 rounded-full aspect-square w-14"
          :src="getImageSrc(organism.image, 'thumbnail')"
          alt=""
          loading="lazy"
        />
        <p class="inline-block uppercase">{{ organism.attribution }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
