<template>
  <div
    :class="[
      'organism brand-window-slant relative overflow-hidden bg-gray-100 layout-' +
        organism.brandWindowLayout,
      'container-' + organism.containerWidth,
    ]"
  >
    <div class="mx-auto max-w-7xl">
      <div
        class="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-[9rem]"
      >
        <main
          class="px-4 pt-10 mx-auto max-w-7xl sm:px-6 lg:pr-8 lg:pl-16 sm:pt-12 md:pt-16 lg:pt-20 xl:pt-40"
        >
          <div class="sm:text-center lg:text-left">
            <h1
              class="text-4xl tracking-tight fr-text-dark theme-text-6xl sm:text-5xl md:text-6xl italic"
            >
              <span class="block">{{ organism.title1 }}</span>
              <span class="block text-fr-brown xl:inline">{{
                organism.title2
              }}</span>
            </h1>
            <div
              class="mt-3 mb-5 text-base fr-text-dark sm:mb-8 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
              v-html="organism.description"
            ></div>
            <MoleculesButtons
              class="mx-10 sm:mx-0"
              :buttons="organism"
              :button-colors="buttonColors"
            />
          </div>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-5/12 xl:w-1/2">
      <svg
        class="absolute hidden w-48 h-full text-white lg:block fill-gray-100"
        fill="currentColor"
        viewBox="0 0 300 100"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="0,0 300,0 50,100 0,100" />
      </svg>
      <img
        class="object-cover object-top w-full h-56 lg:ml-4 lg:object-left sm:h-72 md:h-96 lg:w-full lg:h-full 2xl:object-top"
        :src="getImageSrc(organism.backgroundImage, 'large')"
        alt=""
        loading="lazy"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
